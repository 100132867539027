const siteRoute = "https://midway-starter.netlify.com";

function toPlainText(blocks = []) {
  return blocks
    .map((block: { _type: string; children: any }) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child: { text: any }) => child.text).join("");
    })
    .join("\n\n");
}

export const ProductSchema = (
  main: {
    title: string;
    productDescription?: [];
    mainImage?: {
      asset: {
        url: string;
      };
    };
    slug: {
      current: string;
    };
  },
  shopify: {
    defaultVariant: {
      sku: string;
      price: string;
    };
  }
) => {
  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: main.title,
    image: main.mainImage && main.mainImage.asset.url,
    description:
      main.productDescription && toPlainText(main.productDescription),
    sku: shopify.defaultVariant.sku,
    mpn: shopify.defaultVariant.sku,
    price: shopify.defaultVariant.price,
    brand: {
      "@type": "Thing",
      name: "Sammilit"
    },
    offers: {
      "@type": "Offer",
      url: `${siteRoute}/products/${main.slug.current}`,
      priceCurrency: "USD",
      price: shopify.defaultVariant.price,
      itemCondition: "https://schema.org/UsedCondition",
      availability: "https://schema.org/InStock",
      seller: {
        "@type": "Organization",
        name: "Sammilit"
      }
    }
  };
  return schema;
};
