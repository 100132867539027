import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export const SEO = ({
  pagePath,
  metaInfo
}: {
  pagePath: string;
  metaInfo: {
    metaTitle?: string;
    metaDescription?: string;
    openImage?: string;
    twitterImage?: string;
    twitterTitle?: string;
    openTitle?: string;
    openGraphDescription?: string;
    twitterDescription?: string;
  };
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const siteRoute = site?.siteMetadata?.siteUrl ?? "";

  const title = metaInfo ? (metaInfo.metaTitle ? metaInfo.metaTitle : "") : "";
  const metaDescription = metaInfo
    ? metaInfo.metaDescription
      ? metaInfo.metaDescription
      : ""
    : "";
  const metaKeywords = " ";
  const ogImage = metaInfo
    ? metaInfo.openImage
      ? metaInfo.openImage.asset.url
      : ""
    : "";
  const twitterImage = metaInfo
    ? metaInfo.twitterImage
      ? metaInfo.twitterImage.asset.url
      : ""
    : "";
  const openTitle = metaInfo
    ? metaInfo.openTitle
      ? metaInfo.openTitle
      : title
    : title;
  const openGraphDescription = metaInfo
    ? metaInfo.openGraphDescription
      ? metaInfo.openGraphDescription
      : metaDescription
    : metaDescription;
  const twitterTitle = metaInfo
    ? metaInfo.twitterTitle
      ? metaInfo.twitterTitle
      : title
    : title;
  const twitterDescription = metaInfo
    ? metaInfo.twitterDescription
      ? metaInfo.twitterDescription
      : metaDescription
    : metaDescription;
  return (
    <Helmet title={title}>
      <html lang="en" />
      <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <meta property="og:locale" content="en_US" />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta
        property="og:url"
        content={`${siteRoute}/${pagePath ? pagePath : ""}`}
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={`${openTitle}`} />
      <meta property="og:site_name" content="Sammilit" />
      <meta property="og:description" content={openGraphDescription} />
      <meta name="twitter:site" content="@sammilit" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${twitterTitle}`} />
      <meta name="twitter:image:src" content={twitterImage} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta
        name="twitter:url"
        content={`${siteRoute}/${pagePath ? pagePath : ""}`}
      />

      {/* Chrome, Firefox OS and Opera */}
      <meta name="theme-color" content="#092603" />
      {/* Windows Phone */}
      <meta name="msapplication-navbutton-color" content="#092603" />
      {/* iOS Safari */}
      <meta name="apple-mobile-web-app-status-bar-style" content="#092603" />

      <link rel="manifest" href="/site.webmanifest"></link>
    </Helmet>
  );
};
